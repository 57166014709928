import React, { useState, useEffect } from 'react';

// CampaignTable component to handle dynamic data
const CampaignTable = ({ fetchCampaigns }) => {
    const [campaignsData, setCampaignsData] = useState([]);

    // List of months
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Fetch data on component mount
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const data = await fetchCampaigns();
    //             setCampaignsData(data);
    //         } catch (error) {
    //             console.error('Error fetching campaigns data:', error);
    //         }
    //     };
    //     fetchData();
    // }, [fetchCampaigns]);

    return (
        <div className="w-full bg-white rounded-[1rem] p-4 sm:p-8 mt-6">
            {/* Header section */}
            <div className="w-full grid grid-cols-2 mb-6 gap-4">
                <h2 className="text-[2rem] sm:text-[2.8rem] inter-font font-[700]">Campaigns</h2>
                <div className="w-full flex justify-end">
                    <select className="w-[60%] sm:w-[35%] lg:w-[25%] border border-[#D5D5D5] text-[#2B303466] text-[1.4rem] inter-font font-[500] rounded-lg px-4 py-2">
                        {months.map((month, index) => (
                            <option key={index} value={month}>
                                {month}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Table section with thin scrollbar */}
            <div className="w-full overflow-x-auto scrollbar-thin-custom">
                <table className="w-full table-auto text-[1.2rem] sm:text-[1.4rem]">
                    <thead>
                        <tr className="bg-[#F1F4F9] text-left">
                        <th className="p-4 text-[1.4rem] inter-font font-[700]">Campaign</th>
                        <th className="p-4 text-[1.4rem] inter-font font-[700]">Period</th>
                        <th className="p-4 text-[1.4rem] inter-font font-[700]">Promo</th>
                        <th className="p-4 text-[1.4rem] inter-font font-[700]">Channel</th>
                        <th className="p-4 text-[1.4rem] inter-font font-[700]">Performance</th>
                        <th className="p-4 text-[1.4rem] inter-font font-[700]">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {campaignsData.length === 0 ? (
                            <tr>
                                <td colSpan="6" className="p-4 text-center inter-font font-[400]">No campaigns found</td>
                            </tr>
                        ) : (
                            campaignsData.map((campaign, index) => (
                                <tr key={index} className="border-t">
                                    <td className="p-2 sm:p-4 text-[#202224] inter-font font-[400]">{campaign.name}</td>
                                    <td className="p-2 sm:p-4 text-[#202224] inter-font font-[400]">{campaign.period}</td>
                                    <td className="p-2 sm:p-4 text-[#202224] inter-font font-[400]">{campaign.promo}</td>
                                    <td className="p-2 sm:p-4 flex items-center">
                                        {campaign.channels.map((icon, idx) => (
                                            <img key={idx} src={icon} alt="Channel Icon" className="w-5 sm:w-6 h-5 sm:h-6 rounded-full mr-2" />
                                        ))}
                                        <span className="text-xs sm:text-sm text-gray-500">+{campaign.extraChannels}</span>
                                    </td>
                                    <td className="p-2 sm:p-4 text-[#202224] inter-font font-[400]">
                                        <span className={campaign.performance.includes('-') ? 'text-red-500' : 'text-green-500'}>
                                            {campaign.performance}
                                        </span>
                                    </td>
                                    <td className="p-2 sm:p-4 text-[#202224] inter-font font-[400]">
                                        <span className={`px-2 py-1 sm:px-3 sm:py-1 text-white rounded-full ${campaign.statusColor}`}>
                                            {campaign.status}
                                        </span>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CampaignTable;
