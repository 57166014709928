import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import InfluencerLayout from "./InfluencerLayout/InfluencerLayout";
import BrandLayout from "./BrandLayout/BrandLayout";
// import AgencyLayout from "./AgencyLayout/AgencyLayout";

const PrivateRoute = ({ children, allowedType }) => {
    const AccessToken = useSelector((state) => state.utilReducers?.accessToken);
    const userType = useSelector((state) => state.utilReducers?.userType);

    console.log("AccessToken :", AccessToken);
    console.log("userType :", userType);

    // If no access token, redirect to login
    if (!AccessToken) {
        return <Navigate to="/login" replace />;
    }

    // Restrict based on userType
    if (userType !== allowedType) {
        return <Navigate to="/403" replace />; // Redirect to 403 Forbidden page if userType doesn't match
    }

    // Render the correct layout based on the user type
    if (userType === "Brand") return <BrandLayout>{children}</BrandLayout>;
    if (userType === "Influencer") return <InfluencerLayout>{children}</InfluencerLayout>;
    // if (userType === "Agency") return <AgencyLayout>{children}</AgencyLayout>;

    return null; // Handle edge case where userType doesn't match any type
};

export default PrivateRoute;
