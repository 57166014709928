import React from 'react';
import Landing from '../../components/main/Landing/Landing';

function Dashboard() {
    return (
        <div className='w-full h-full'>
            <Landing />
        </div>

    );
}

export default Dashboard;
