import React from 'react'

const CampaignCard = ({ title, daysLeft, progress, imgSrc }) => {
    return (
      <div className="bg-white p-8 rounded-[1.4rem]">
        <img src={imgSrc} alt={title} className="h-[14.1rem] w-[31.6rem] object-cover rounded-[2.1rem]"/>
        <div className="mt-4">
          <h4 className="text-[1.6rem] text-[#5F5C5C] inter-font font-[500] mt-4">{title}</h4>
          <p className='text-[1.4rem] text-[#5F5C5C] inter-font font-[400] mt-4'>{daysLeft} days left</p>
          <p className='text-[1.4rem] text-[#32DC8B] inter-font font-[400] mt-4'>6/6</p>
          <p className='text-[1rem] text-[#5F5C5C] inter-font font-[400] mt-4'>Progress: {progress}%</p>
          <div className="bg-[#D9D9D9] rounded-full h-2 mt-4 mb-[2rem]">
            <div className="bg-[#32DC8B] h-2 rounded-full " style={{ width: `${progress}%` }}></div>
          </div>
        </div>
      </div>
    );
  };
  
  export default CampaignCard;