import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import BottomNavigation from './BrandCampaignLayout/BottomNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../../hooks/useToast';
import {getMaxViews} from "../../../store/campaignStore/actions/CreateCampaignActions/getMaxViewsActions";
import {getBudget} from "../../../store/campaignStore/actions/CreateCampaignActions/getBudgetActions";
import {
    setBrandCampaignCreationData,
    setBrandCampaignCreationStep
} from "../../../store/publicStore/actions/utilsActions/utilsActions";
import {getBrandCampaign} from "../../../store/campaignStore/actions/CreateCampaignActions/getBrandCampaignActions";
import {createCampaign} from "../../../store/campaignStore/actions/CreateCampaignActions/createCampaignActions";
import 'rc-slider/assets/index.css';
import './AudienceDemographicDetails.css';

const { Handle, SliderTooltip } = Slider;

const TooltipHandle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <SliderTooltip
            prefixCls="rc-slider-tooltip"
            overlay={`${value} views`}
            visible={dragging}
            placement="top"
            key={index}
        >
            <Handle {...restProps} />
        </SliderTooltip>
    );
};

// Modal component
const ConfirmationModal = ({ onConfirm, onCancel, budget }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-[3.2rem] rounded-lg shadow-lg max-w-[60rem] w-full">
            <h3 className="text-[3.2rem] font-semibold mb-[1rem] text-[#0D9B50]">Confirmation!</h3>
            <p className='text-[2rem]'>Are you sure you want to use ${budget} from your wallet for this campaign?</p>
            <div className="mt-6 flex justify-end gap-4 text-[1.6rem]">
                <button onClick={onCancel} className="px-4 py-2 bg-gray-300 rounded">Cancel</button>
                <button onClick={onConfirm} className="px-4 py-2 bg-green-500 text-white rounded">Confirm</button>
            </div>
        </div>
    </div>
);

const EstimatedBudget = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Toast = useToast();

    const { brandCampaignCreationData } = useSelector((state) => state.utilReducers);
    const getBudgetReducerData = useSelector(state => state.getBudgetReducer?.data);
    const getBudgetReducerMessage = useSelector(state => state.getBudgetReducer?.message);
    const getMaxViewsReducerData = useSelector(state => state.getMaxViewsReducer?.data);

    const [expectedViews, setExpectedViews] = useState(140000);
    const [formErrors, setFormErrors] = useState({});
    const [selectedInfluencer, setSelectedInfluencer] = useState("Nano");
    const [isModalOpen, setIsModalOpen] = useState(false); // For modal visibility

    // Define an array of influencer types
    const influencerTypes = ["Nano", "Micro", "Medium", "Large"];

    useEffect(() => {
        dispatch(getMaxViews({
            platform: brandCampaignCreationData?.basicDetails?.platform,
            campaignType: brandCampaignCreationData?.basicDetails?.campaignType === "User Generated Content" ? "UGC" : brandCampaignCreationData?.basicDetails?.campaignType.toUpperCase(),
            regionId: Number(brandCampaignCreationData?.audienceDemographicDetails?.region),
            countryId: Number(brandCampaignCreationData?.audienceDemographicDetails?.country),
            stateId: Number(brandCampaignCreationData?.audienceDemographicDetails?.state),
            gender: brandCampaignCreationData?.audienceDemographicDetails?.gender,
            nicheId: brandCampaignCreationData?.audienceDemographicDetails?.niche,
            age: brandCampaignCreationData?.audienceDemographicDetails?.ageGroup,
        }));
    }, [brandCampaignCreationData]);

    useEffect(() => {
        dispatch(getBudget({
            platform: brandCampaignCreationData?.basicDetails?.platform,
            campaignType: brandCampaignCreationData?.basicDetails?.campaignType === "User Generated Content" ? "UGC" : brandCampaignCreationData?.basicDetails?.campaignType.toUpperCase(),
            regionId: Number(brandCampaignCreationData?.audienceDemographicDetails?.region),
            countryId: Number(brandCampaignCreationData?.audienceDemographicDetails?.country),
            stateId: Number(brandCampaignCreationData?.audienceDemographicDetails?.state),
            gender: brandCampaignCreationData?.audienceDemographicDetails?.gender,
            nicheId: brandCampaignCreationData?.audienceDemographicDetails?.niche,
            age: brandCampaignCreationData?.audienceDemographicDetails?.ageGroup,
            selectedViews: Number(expectedViews),
        }));
    }, [expectedViews]);

    function formatDateTime(input) {
        const date = new Date(input);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = "50";
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const handleCleanUp = () => {
        dispatch(setBrandCampaignCreationData({
            basicDetails: {
                campaignName: null,
                campaignPurpose: null,
                platform: null,
                campaignType: null,
            },
            contentDetails: {
                startDate: null,
                endDate: null,
                bannerTemplate: {},
                videoPosition: null,
                mediaFileLink: null,
                script: null,
                dialogue: null,
                backgroundColor: null,
                otherProperties: null,
                isLocationRequired: null,
                isBrandApprovalRequired: null,
                uploadDuration: null,
            },
            audienceDemographicDetails: {
                ageGroup: null,
                country: null,
                niche: null,
                region: null,
                gender: null,
                state: null,
            }
        }));
        dispatch(setBrandCampaignCreationStep(1));
        dispatch(getBrandCampaign());
        navigate('/brand/campaigns')
    }

    const handleNext = () => {
        setIsModalOpen(true); // Open confirmation modal
    };

    const confirmAndProceed = () => {
        dispatch(createCampaign({
            "campaignName": brandCampaignCreationData?.basicDetails?.campaignName,
            "purpose": brandCampaignCreationData?.basicDetails?.campaignPurpose,
            "platform": brandCampaignCreationData?.basicDetails?.platform,
            "campaignType": brandCampaignCreationData?.basicDetails?.campaignType === "User Generated Content" ? "UGC" : brandCampaignCreationData?.basicDetails?.campaignType.toUpperCase(),
            "startDate": brandCampaignCreationData?.contentDetails?.startDate,
            "endDate": brandCampaignCreationData?.contentDetails?.endDate,
            "bannerTemplate": brandCampaignCreationData?.contentDetails?.bannerTemplate,
            "videoPosition": brandCampaignCreationData?.contentDetails?.videoPosition,
            "mediaFileLink": brandCampaignCreationData?.contentDetails?.mediaFileLink,
            "script": brandCampaignCreationData?.contentDetails?.script,
            "dialogue": brandCampaignCreationData?.contentDetails?.dialogue,
            "backgroundColor": brandCampaignCreationData?.contentDetails?.backgroundColor,
            "otherProperties": brandCampaignCreationData?.contentDetails?.otherProperties,
            "isLocationRequired": brandCampaignCreationData?.contentDetails?.isLocationRequired,
            "isBrandApprovalRequired": brandCampaignCreationData?.contentDetails?.isBrandApprovalRequired,
            "uploadDuration": formatDateTime(brandCampaignCreationData?.contentDetails?.uploadDuration),
            "regionId": Number(brandCampaignCreationData?.audienceDemographicDetails?.region),
            "countryId": Number(brandCampaignCreationData?.audienceDemographicDetails?.country),
            "stateId": Number(brandCampaignCreationData?.audienceDemographicDetails?.state),
            "campaignAgeGroups": brandCampaignCreationData?.audienceDemographicDetails?.ageGroup,
            "gender": brandCampaignCreationData?.audienceDemographicDetails?.gender,
            "niches": brandCampaignCreationData?.audienceDemographicDetails?.niche,
            "influencerType": selectedInfluencer.toUpperCase(),
            "targetView": Number(expectedViews),
            "estimatedBudget": Number(getBudgetReducerData?.estimatedBudget)
        }, (response) => {
            // Toast(getBudgetReducerMessage, 'success');
            console.log("create campaign response : ", response)
            if (response && response?.status === 200){
                setIsModalOpen(false); // Close modal
                Toast("Campaign Created successfully", 'success');
                handleCleanUp();
            }else{
                Toast(response.message, 'error');

            }

        }))
    };

    return (
        <div className='bg-[#FFFFFF66] shadow-xl shadow-[#E3E8EF] p-8 rounded-lg w-full'>
            <h2 className="text-[1.6rem] text-[#111729] inter-font font-[400] mb-4">Influencer Type</h2>
            <div className="flex gap-4 mb-[3.2rem]">
                {influencerTypes.map((type) => (
                    <label key={type} className="flex items-center text-[1.6rem] text-[#111729] inter-font font-[400] mr-[3.2rem]">
                        <input
                            type="radio"
                            name="influencer"
                            value={type}
                            checked={selectedInfluencer === type}
                            onChange={(event) => {setSelectedInfluencer(event.target.value)}}
                            className="mr-2"
                        />
                        {type}
                    </label>
                ))}
            </div>

            <div className="mb-[3.2rem]">
                <label className="text-[1.6rem] text-[#111729] inter-font font-[400]">
                    What's your expected views for this campaign?
                </label>
                <div className='w-full h-[5rem] flex justify-center items-center'>
                    <div className='w-[2.7rem] h-[2.7rem] flex justify-center items-center rounded-full bg-[#43AB4E]'></div>
                    <Slider
                        min={10000}
                        max={getMaxViewsReducerData?.estimatedViews}
                        step={10000}
                        value={expectedViews}
                        onChange={(value) => setExpectedViews(value)}
                        trackStyle={[{ backgroundColor: '#22c55e' }]}
                        handleStyle={[{ backgroundColor: '#43AB4E' }]}
                        railStyle={{ backgroundColor: '#B8B0B0' }}
                        handle={TooltipHandle}
                    />
                    <div className='w-[2.7rem] h-[2.7rem] flex justify-center items-center rounded-full bg-[#EEf0F1]'></div>
                </div>
                <div className="flex justify-between text-sm mt-2">
                    <span className='text-[1.6rem] text-[#111729] inter-font font-[500]'>10K</span>
                    <span className='text-[1.6rem] text-[#111729] inter-font font-[500]'>{(expectedViews / 1000).toLocaleString()}K</span>
                    <span className='text-[1.6rem] text-[#111729] inter-font font-[500]'>{`${getMaxViewsReducerData?.estimatedViews / 1000}k`}</span>
                </div>
            </div>

            <div className="w-[40rem] sm:w-[60.4rem] 2xl:w-[80rem] mb-[3.2rem]">
                <h3 className="text-[1.6rem] text-[#111729] inter-font font-[400] mb-2">Estimated Budget</h3>
                <h3 className="w-full border border-gray-300 text-[1.6rem] text-[#111729] inter-font font-[400] rounded-lg px-4 py-4 focus:outline-none">$ {getBudgetReducerData?.estimatedBudget}</h3>
            </div>
            <BottomNavigation isNextDisabled={Object.keys(formErrors).length > 0 || !getMaxViewsReducerData} handleNext={handleNext} />

            {isModalOpen && (
                <ConfirmationModal
                    budget={getBudgetReducerData?.estimatedBudget}
                    onConfirm={confirmAndProceed}
                    onCancel={() => setIsModalOpen(false)}
                />
            )}
        </div>
    );
};

export default EstimatedBudget;
