import React, {useEffect, useState} from 'react';
import {LoginPageImg} from '../../static/constant/imgConstants';
import {useDispatch, useSelector} from 'react-redux';
import {useToast} from '../../hooks/useToast';
import {useNavigate} from 'react-router';
import PublicHeader from '../../components/utility/PublicHeader/PublicHeader';
import VerifyPopup from '../../components/main/VerifyPopup/VerifyPopup';
import {FaCheck, FaTimes} from 'react-icons/fa';
import {
    verifyOtpAndCreateUser,
    verifyOtpAndCreateUserReset,
    verifyOtpAndCreateUserResetAll
} from "../../store/authStore/actions/authActions/verifyOtpAndCreateUserActions";

function Verify() {
    const dispatch = useDispatch();
    const Toast = useToast();
    const navigate = useNavigate();

    const utilReducers = useSelector(state => state.utilReducers)
    const verifyOtpAndCreateUserReducer = useSelector(state => state.verifyOtpAndCreateUserReducer)
    //const verifyOtpForLoginReducer = useSelector(state => state.verifyOtpForLoginReducer)

    const [otpEmail, setOtpEmail] = useState(new Array(4).fill(''));
    const [otpMobile, setOtpMobile] = useState(new Array(4).fill(''));
    const [message, setMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]?$/.test(value)) { // Only allow numeric input
            const newOtp = [...otpEmail];
            newOtp[index] = value;
            setOtpEmail(newOtp);

            // Move to the next input if current one is filled
            if (value && index < 3) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        // Move to the previous input if Backspace is pressed and input is empty
        if (e.key === 'Backspace' && !otpEmail[index] && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData('text').slice(0, 4).split('');
        if (/^\d+$/.test(paste.join(''))) {
            setOtpEmail(paste);
            paste.forEach((value, index) => {
                if (index < 4) {
                    document.getElementById(`otp-input-${index}`).value = value;
                }
            });
        }
    };

    const handleChange2 = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]?$/.test(value)) { // Only allow numeric input
            const newOtp = [...otpMobile];
            newOtp[index] = value;
            setOtpMobile(newOtp);

            // Move to the next input if current one is filled
            if (value && index < 3) {
                document.getElementById(`mobile-input-${index + 1}`).focus();
            }
        }
    };

    const handleKeyDown2 = (e, index) => {
        // Move to the previous input if Backspace is pressed and input is empty
        if (e.key === 'Backspace' && !otpMobile[index] && index > 0) {
            document.getElementById(`mobile-input-${index - 1}`).focus();
        }
    };

    const handlePaste2 = (e) => {
        const paste = e.clipboardData.getData('text').slice(0, 4).split('');
        if (/^\d+$/.test(paste.join(''))) {
            setOtpMobile(paste);
            paste.forEach((value, index) => {
                if (index < 4) {
                    document.getElementById(`mobile-input-${index}`).value = value;
                }
            });
        }
    };

    const handleVerify = () => {
        if (otpEmail.join('').length !== 4 || otpMobile.join('').length !== 4) {
            setMessage('* Please enter valid OTP');
        } else {
            setMessage('');
            dispatch(verifyOtpAndCreateUser({
                sessionCode: utilReducers.sessionToken,
                phoneOtp: otpMobile.join(''),
                emailOtp: otpEmail.join('')
            }));
            // dispatch(verifyOtpForLogin({
            //     sessionCode: utilReducers.sessionToken,
            //     phoneOtp: otpMobile.join(''),
            //     emailOtp: otpEmail.join('')
            // }));
        }
    };

    useEffect(() => {
        if(verifyOtpAndCreateUserReducer.status === 200){
            Toast(verifyOtpAndCreateUserReducer.message, 'success' )
            setIsOpen(true)
            dispatch(verifyOtpAndCreateUserReset())
        }else if(verifyOtpAndCreateUserReducer.status === 302){
            Toast(verifyOtpAndCreateUserReducer.message, 'error' )
            // navigate('/registration')
            dispatch(verifyOtpAndCreateUserReset())
        }else if(verifyOtpAndCreateUserReducer.status === 303){
            Toast(verifyOtpAndCreateUserReducer.message, 'error' )
            navigate('/registration')
            dispatch(verifyOtpAndCreateUserReset())
        }else if(verifyOtpAndCreateUserReducer.status === 305){
            // Toast(verifyOtpAndCreateUserReducer.message, 'error' )
            setMessage(verifyOtpAndCreateUserReducer?.message)
            // navigate('/registration')
            dispatch(verifyOtpAndCreateUserReset())
        }else if(verifyOtpAndCreateUserReducer.status === 403){
            Toast(verifyOtpAndCreateUserReducer.message, 'error' )
            dispatch(verifyOtpAndCreateUserReset())
        }else if(verifyOtpAndCreateUserReducer.status){
            Toast(verifyOtpAndCreateUserReducer.message, 'info' )
            dispatch(verifyOtpAndCreateUserReset())
        }
    },[verifyOtpAndCreateUserReducer]);

    useEffect(() => {
        setMessage('');
        dispatch(verifyOtpAndCreateUserResetAll())
    },[])

    // useEffect(() => {
    //     if(verifyOtpForLoginReducer.status === 200){
    //         Toast(verifyOtpForLoginReducer.message, 'success' )
    //         dispatch(verifyOtpForLoginReset())
    //         navigate('/')
    //     }else if(verifyOtpForLoginReducer.status === 302){
    //         Toast(verifyOtpForLoginReducer.message, 'error' )
    //         // navigate('/registration')
    //         dispatch(verifyOtpForLoginReset())
    //     }else if(verifyOtpForLoginReducer.status === 403){
    //         Toast(verifyOtpForLoginReducer.message, 'error' )
    //         dispatch(verifyOtpForLoginReset())
    //     }else if(verifyOtpForLoginReducer.status){
    //         Toast(verifyOtpForLoginReducer.message, 'info' )
    //         dispatch(verifyOtpForLoginReset())
    //     }
    // },[verifyOtpForLoginReducer]);


    return (
        <div className='flex flex-col lg:flex-row w-full h-full  justify-between items-center lg:items-start overflow-auto lg:overflow-hidden'>
            <VerifyPopup isOpen={isOpen} modalAction={() => navigate('/')}/>
            <div className='flex flex-col justify-start items-center w-full lg:w-[58%] min-h-max xl:h-full px-[4.8rem] py-[3rem]'>
                <PublicHeader />
                <div className='flex flex-grow flex-col justify-start items-start text-[1.6rem] gap-[1rem] mt-[2rem] lg:mt-[1rem] lg:gap-[0rem] w-[90%] md:w-[60%] lg:w-[40%] min-h-max lg:h-[90%]'>
                    <h2 className="text-[1.6rem] text-left  md:text-[1.8rem] inter-font font-[600] mb-3">
                        Email OTP
                    </h2>
                    <p className="text-[1.4rem] text-start inter-font font-[400]  md:text-[1.6rem] text-[#313957] mb-4">
                        Enter your email for the verification proccess,we will send 4 digits code to your email.
                    </p>
                    <div className="flex justify-between items-center w-full" onPaste={handlePaste}>
                        {otpEmail.map((value, index) => (
                            <input
                                key={index}
                                id={`otp-input-${index}`}
                                type="text"
                                maxLength="1"
                                value={value}
                                onChange={(e) => {
                                    if(otpEmail){

                                    }
                                    handleChange(e, index)
                                }}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                className={`w-[4rem] lg:w-[5rem] h-[4rem] lg:h-[5rem] border rounded-[.8rem] text-center text-[1.6rem] ${(message && !verifyOtpAndCreateUserReducer?.data?.isEmailOtpVerified) ? 'border-red-500' : 'border-gray-300'}`}
                            />
                        ))}
                        <div className='w-[16%]'>
                            {Object.keys(verifyOtpAndCreateUserReducer?.data).length !== 0 ?
                                verifyOtpAndCreateUserReducer?.data?.isEmailOtpVerified ? <FaCheck className='text-green-500' size='20' /> : <FaTimes className='text-red-500' size='20' />
                                : null
                            }
                        </div>
                    </div>
                    {(message && !verifyOtpAndCreateUserReducer?.data?.isEmailOtpVerified) && <p className="text-left text-red-500 w-full">{message}</p>}

                    <h2 className="text-[1.6rem] text-left inter-font font-[600] md:text-[1.8rem] font-semibold mb-3 mt-[2rem] lg:mt-[5rem]">
                        Mobile OTP
                    </h2>
                    <p className="text-[1.4rem] text-start inter-font font-[400] md:text-[1.6rem] text-[#313957] mb-4">
                        Enter your Mobile for the verification proccess,we will send 4 digits code to your Mobile.
                    </p>
                    <div className="flex justify-between items-center w-full" onPaste={handlePaste2}>
                        {otpMobile.map((value, index) => (
                            <input
                                key={index}
                                id={`mobile-input-${index}`}
                                type="text"
                                maxLength="1"
                                value={value}
                                onChange={(e) => handleChange2(e, index)}
                                onKeyDown={(e) => handleKeyDown2(e, index)}
                                className={`w-[4rem] lg:w-[5rem] h-[4rem] lg:h-[5rem] border rounded-[.8rem] text-center text-[1.6rem] ${(message && !verifyOtpAndCreateUserReducer?.data?.isPhoneOtpVerified) ? 'border-red-500' : 'border-gray-300'}`}
                            />
                        ))}
                        <div className='w-[16%]'>
                            {Object.keys(verifyOtpAndCreateUserReducer?.data).length !== 0 ?
                                verifyOtpAndCreateUserReducer?.data?.isPhoneOtpVerified ? <FaCheck className='text-green-500' size='20' /> : <FaTimes className='text-red-500' size='20' />
                                : null
                            }
                        </div>
                    </div>
                    {(message && !verifyOtpAndCreateUserReducer?.data?.isPhoneOtpVerified) && <p className="text-left text-red-500 w-full">{message}</p>}
                    <button
                        type="submit"
                        className="w-full bg-[#0D9B50] roboto-thin font-[400] text-[2rem] text-white py-3 mb-4 mt-[3rem] lg:mt-[8rem] rounded-[1.2rem] hover:bg-green-700"
                        onClick={handleVerify}
                    >
                        Submit
                    </button>
                </div>
            </div>
            <div className='flex flex-col justify-center items-center w-[90%] md:w-[60%] lg:w-[42%] min-h-max lg:h-full'>
                <img src={LoginPageImg.loginlogo} alt="" className="w-full h-full" />
            </div>
        </div>
    );
}

export default Verify;
