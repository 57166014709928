import {all} from "redux-saga/effects";

import getAllCountrySaga from "../publicStore/sagas/publicSagas/getAllCountrySaga";

import loginSaga from "../authStore/sagas/authSagas/loginSaga";
import googleUserLoginSaga from "../authStore/sagas/authSagas/googleUserLoginSaga";
import getUserInfoSaga from "../authStore/sagas/authSagas/getUserInfoSaga";
import createUserSaga from "../authStore/sagas/authSagas/createUserSaga";
import verifyOtpAndCreateUserSaga from "../authStore/sagas/authSagas/verifyOtpAndCreateUserSaga";
import sendOtpForForgetPasswordSaga from "../authStore/sagas/authSagas/sendOtpForForgetPasswordSaga";
import verifyOtpForForgetPasswordSaga from "../authStore/sagas/authSagas/verifyOtpForForgetPasswordSaga";
import forgetPasswordResetSaga from "../authStore/sagas/authSagas/forgetPasswordResetSaga";
import sendOtpToCreateUserSaga from "../authStore/sagas/authSagas/sendOtpToCreateUserSaga";
import userLoginSaga from "../authStore/sagas/authSagas/userLoginSaga";
import verifyOtpForLoginSaga from "../authStore/sagas/authSagas/verifyOtpForLoginSaga";

import getAllRegionSaga from "../campaignStore/sagas/campaignMasterSagas/getAllRegionSaga";
import getCountryByRegionSaga from "../campaignStore/sagas/campaignMasterSagas/getCountryByRegionSaga";
import getStateByCountrySaga from "../campaignStore/sagas/campaignMasterSagas/getStateByCountrySaga";
import getAllAgeGroupSaga from "../campaignStore/sagas/campaignMasterSagas/getAllAgeGroupSaga";
import getAllNicheSaga from "../campaignStore/sagas/campaignMasterSagas/getAllNicheSaga";

import getBudgetSaga from "../campaignStore/sagas/createCampaignSagas/getBudgetSaga";
import getMaxViewsSaga from "../campaignStore/sagas/createCampaignSagas/getMaxViewsSaga";
import getBrandCampaignSaga from "../campaignStore/sagas/createCampaignSagas/getBrandCampaignSaga";
import getAllBannerTemplateSaga from "../campaignStore/sagas/createCampaignSagas/getAllBannerTemplateSaga";
import uploadCampaignFileSaga from "../campaignStore/sagas/createCampaignSagas/uploadCampaignFileSaga";
import createCampaignSaga from "../campaignStore/sagas/createCampaignSagas/createCampaignSaga";
import getMyBrandBusinessDetailSaga from "../BusinessStore/sagas/BrandBusinessSagas/getMyBrandBusinessDetailSaga";
import searchBusinessPlaceSaga from "../BusinessStore/sagas/BrandBusinessSagas/searchBusinessPlaceSaga";
import registerBusinessSaga from "../BusinessStore/sagas/BrandBusinessSagas/registerBusinessSaga";





export default function* rootSaga() {
    yield all([
        // public sagas
        getAllCountrySaga(),

        // auth sagas
        loginSaga(),
        googleUserLoginSaga(),
        getUserInfoSaga(),
        createUserSaga(),
        verifyOtpAndCreateUserSaga(),
        sendOtpForForgetPasswordSaga(),
        verifyOtpForForgetPasswordSaga(),
        forgetPasswordResetSaga(),
        sendOtpToCreateUserSaga(),
        userLoginSaga(),
        verifyOtpForLoginSaga(),

        // Campaign Master Sagas
        getAllRegionSaga(),
        getCountryByRegionSaga(),
        getStateByCountrySaga(),
        getAllAgeGroupSaga(),
        getAllNicheSaga(),

        // Create Campaign Sagas
        getBudgetSaga(),
        getMaxViewsSaga(),
        getBrandCampaignSaga(),
        getAllBannerTemplateSaga(),
        uploadCampaignFileSaga(),
        createCampaignSaga(),
        getMyBrandBusinessDetailSaga(),
        searchBusinessPlaceSaga(),
        registerBusinessSaga(),

    ])
}
