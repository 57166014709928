import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {Navigate, Route, Routes} from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastProvider } from './hooks/useToast';
import store, { persistor } from './store/configStore';
import PrivateRoute from './components/utility/PrivateRoute';
import Login from './hoc/Login/Login';
import Dashboard from './hoc/Dashboard/Dashboard';
import Registration from './hoc/Registration/Registration';
import ForgotPassword from './hoc/ForgotPassword/ForgotPassword';
import ResetPassword from './hoc/ResetPassword/ResetPassword';
import Verify from './hoc/Verify/Verify';
import VerifyEmailOtp from './hoc/Verify/VerifyEmailOtp';
import DashboardBrand from './hoc/DashboardBrand/DashboardBrand';
import MyCampaigns from './components/main/MyCampaigns/MyCampaigns';
import CreateCampaign from "./hoc/CreateCampaign/CreateCampaign";
import Forbidden from './hoc/Forbidden/Forbidden';
import NotFound from './hoc/NotFound/NotFound';
import './App.css';
import BrandBusinessDashboard from './hoc/BrandBusinessDashboard/BrandBusinessDashboard';

function App() {
  const state = store.getState();
  const userType = state.utilReducers?.userType

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className="App">
          {/* Wrap the application with GoogleOAuthProvider */}
          <GoogleOAuthProvider clientId="951516113922-aen49fhaed94ou3v2afobrdk47ns3304.apps.googleusercontent.com">
            <ToastProvider defaultPosition="top-center" defaultDuration={5000}>
              <Routes>
                {/* Public Routes */}
                <Route path="/login" element={<Login />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:userType" element={<ResetPassword />} />
                <Route path="/verifyOtp" element={<Verify />} />
                <Route path="/verifyEmailOtp/:userType" element={<VerifyEmailOtp />} />
                {/* Forbidden Route */}
                <Route path="/403" element={<Forbidden />} />
                {/* Catch-all Route for 404 */}
                <Route path="*" element={<NotFound />} />
                {/* Wrap protected routes in PrivateRoute */}
                <Route exact path="/" element={ userType === "Brand" ? <Navigate to="/brand" /> : <Navigate to="/influencer" /> } />
                {/* Brand Routes */}
                <Route path="/brand/*" element={<PrivateRoute allowedType="Brand"><DashboardBrand /></PrivateRoute>} />
                <Route path="/brand/campaigns" element={<PrivateRoute allowedType="Brand"><MyCampaigns /></PrivateRoute>} />
                <Route path="/brand/campaigns/create" element={<PrivateRoute allowedType="Brand"><CreateCampaign /></PrivateRoute>} />
                <Route path="/brand/busisness" element={<PrivateRoute allowedType="Brand"><BrandBusinessDashboard /></PrivateRoute>} />
                {/* Influencer Routes */}
                <Route path="/influencer/*" element={<PrivateRoute allowedType="Influencer"><Dashboard /></PrivateRoute>} />
                
                {/* Agency Routes */}
                {/* <Route path="/agency/*" element={<PrivateRoute allowedType="Agency"><AgencyDashboard /></PrivateRoute>} /> */}
                {/* <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} /> */}

                {/* <Route path="/dashboard-social-account" element={<PrivateRoute><DashboardSocialAccount /></PrivateRoute>} />
                <Route path="/pay-out-dashboard" element={<PrivateRoute><PayoutDashboard /></PrivateRoute>} />
                <Route path="/dashboard-shipping-address" element={<PrivateRoute><DashboardShippingAddress /></PrivateRoute>} />
                <Route path="/resource-page" element={<ResourcePage />} /> */}
              </Routes>
            </ToastProvider>
          </GoogleOAuthProvider>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
