export const base_url = "https://dev.paidpromo.ai/api" // dev base URL


export const endpoints = {
    login: "/paidpromo/auth/login", // Generate OTP
    googleUserLogin: "/paidpromo/auth/googleUserLogin", // Generate OTP
    getUserInfo: "/paidpromo/user/me", // Get Logged in User Details 
    createUser: "/paidpromo/user/sendOtpToCreateUser", // Send Otp To Create User 
    verifyOtpAndCreateUser: "/paidpromo/user/verifyOtpAndCreateUser", // Verify Otp To Create User 
    sendOtpForForgetPassword: "/paidpromo/auth/sendOtpForForgetPassword", // send Otp For Forget Password 
    verifyOtpForForgetPassword: "/paidpromo/auth/verifyOtpForForgetPassword", // verify Otp For Forget Password 
    forgetPasswordReset: "/paidpromo/auth/forgetPasswordReset", // reset Forget Password 
    getAllCountry: "/paidpromo/country/getAllCountry", // reset Forget Password 
    sendOtpToCreateUser: "/paidpromo/user/sendOtpToCreateUser",
    userLogin: "/paidpromo/auth/userLogin",
    verifyOtpForLogin: "/paidpromo/auth/verifyOtpForLogin",
    getAllRegion: "/paidpromo/region/getAllRegion",
    getCountryByRegion: "/paidpromo/country/getCountryByRegion?regionId=",
    getStateByCountry: "/paidpromo/state/getStateByCountry?countryId=",
    getAllAgeGroup: "/paidpromo/ageGroup/getAllAgeGroup",
    getAllNiche: "/paidpromo/niche/getAllNiche",
    getBudget: "/paidpromo/campaign/getBudget",
    getMaxViews: "/paidpromo/campaign/getMaxViews",
    getBrandCampaign: "/paidpromo/campaign/getBrandCampaign",
    getAllBannerTemplate: "/paidpromo/bannerTemplate/getAllBannerTemplate",
    uploadCampaignFile: "/paidpromo/campaign/uploadCampaignFile",
    createCampaign: "/paidpromo/campaign/createCampaign",
    getMyBrandBusinessDetail: "/paidpromo/brandBusiness/getMyBrandBusinessDetail",
    searchBusinessPlace: "/paidpromo/brandBusiness/searchBusinessPlace",
    registerBusiness: "/paidpromo/brandBusiness/registerBusiness",
}