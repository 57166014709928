import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiFilter, FiMoreVertical, FiSearch } from 'react-icons/fi';
import { SidebarImg } from '../../../static/constant/imgConstants';
import {useNavigate} from "react-router-dom";
import Breadcrumbs from "../../common/Breadcrumbs/Breadcrumbs";
import {getBrandCampaign} from "../../../store/campaignStore/actions/CreateCampaignActions/getBrandCampaignActions";


const MyCampaigns = () => {
    const getBrandCampaignReducerData = useSelector(state => state.getBrandCampaignReducer.data);

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];


    const getStatusStyle = (status) => {
        switch (status) {
            case 'APPROVED':
                return 'bg-[#00B69B] text-[#FFFFFF]';
            case 'PENDING_APPROVAL':
                return 'bg-[#FCBE2D] text-[#FFFFFF]';
            case 'REJECTED':
                return 'bg-[#FD5454] text-[#FFFFFF]';
            default:
                return 'bg-[#FCBE2D] text-[#FFFFFF]';
        }
    };


     useEffect(() => {
        dispatch(getBrandCampaign());
    }, [])

    return (
        <div className="w-full h-full flex flex-col justify-start bg-[#EDEEF1] p-4">
            <div className="w-full h-full flex flex-col px-[2rem]">
                <Breadcrumbs />
                <div className='w-full flex justify-between items-center my-[4rem] px-[1rem]'>
                    <div className="relative">
                        <FiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 w-[2.1rem] h-[2.1rem] text-gray-400" />
                        <input
                            type="text"
                            placeholder="Search campaigns"
                            className="w-[20rem] p-3 border text-[1.4rem] inter-font font-[400] border-gray-300 rounded-[1rem] pl-16 outline-none"
                        />
                    </div>

                    <div className='flex space-x-[1.2rem]'>
                        <button className="bg-white p-4 rounded-[1rem] outline-none">
                            <FiFilter className="inline-block w-[2.1rem] h-[2.1rem]" />
                        </button>
                        <button className="flex items-center bg-[#0D9B50] text-[1.6rem] text-white px-4 py-2 rounded-[1rem] outline-none" onClick={() => {navigate('/brand/campaigns/create')}}>
                            <img src={SidebarImg.campaigns1} alt="Campaign Icon" className="w-[2.1rem] h-[2.1rem]" />
                            + <h1 className='inter-font font-[500] px-4'>New Campaigns</h1>
                        </button>
                    </div>
                </div>
                <div className='w-full flex flex-col justify-between rounded-[2.6rem] bg-[#ffffff] p-[2.4rem]'>
                    <h1 className="text-[2.4rem] leading-[5rem] text-[#202224] inter-font font-[700] mb-[1.2rem]">Campaigns</h1>
                    <div className="mb-[4rem] w-full !overflow-y-scroll no-scrollbar">
                        <table className="min-w-full bg-white relative">
                            <thead className='!sticky top-0'>
                                <tr className="border-b bg-[#F1F4F9] text-[1.4rem] text-[#202224] inter-font font-[700]">
                                    <th className="p-4 text-left">Campaign</th>
                                    <th className="p-4 text-left">Purpose</th>
                                    <th className="p-4 text-left">Platform</th>
                                    <th className="p-4 text-left">Campaign Type</th>
                                    <th className="p-4 text-left">Status</th>
                                    <th className="p-4 text-left">Start Date</th>
                                    <th className="p-4 text-left">End Date</th>
                                    <th className="p-4 text-left">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(getBrandCampaignReducerData) && getBrandCampaignReducerData.map((campaign, index) => (
                                    <tr key={index} className="border-b text-[1.4rem] text-[#202224] inter-font font-[600]">
                                        <td className="p-4">{campaign.name}</td>
                                        <td className="p-4">{campaign.purpose}</td>
                                        <td className="p-4">{campaign.platform}</td>
                                        <td className="p-4">{campaign.campaignType}</td>
                                        <td className="p-4">
                                            <span
                                                className={`inline-block px-3 py-1 w-full h-[2.7rem] text-center rounded-full text-[1.4rem] inter-font font-[700] ${getStatusStyle(
                                                    campaign.campaignStatus
                                                )}`}
                                            >
                                                {campaign.campaignStatus}
                                            </span>
                                        </td>
                                        <td className="p-4">{campaign.startDate}</td>
                                        <td className="p-4">{campaign.endDate}</td>
                                        <td className="p-4">
                                            <FiMoreVertical className="cursor-pointer" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='flex mt-auto justify-center'>
                    <div className="flex justify-center items-center text-[#959CB6] mt-auto mx-auto text-center text-[1.6rem]">
                        © 2024 ALL RIGHTS RESERVED
                    </div>
                    <div className="text-[1.4rem] text-[#8A98AC] flex justify-center space-x-2 gap-2 mr-8">
                        <a href="#" className="hover:underline">Privacy Policy</a>
                        <a href="#" className="hover:underline">Cookies</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyCampaigns;
