import React from 'react'

const MediaSource = ({ name, progress, imgSrc }) => {
    return (
      <div className="flex flex-col justify-center mb-4">
        <div className='flex  justify-between'>
        <div className="flex items-center gap-[1rem] my-[0.2rem]">
          <img src={imgSrc} alt={name} className="h-[3.1rem] w-[3.1rem] mr-2"/>
          <span className='text-[1.2rem] inter-font font-[400] '>{name}</span>
        </div>
        <span className=" text-[1.2rem] inter-font font-[400] ml-4">{progress}%</span>
        </div>
        <div className="w-[98%] bg-[#D9D9D9] justify-center items-center rounded-full h-2 mb-[1rem] ml-4">
          <div className="bg-[#32DC8B] h-2 rounded-full" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
    );
  };
  
  export default MediaSource;