import React, { useEffect, useState } from 'react';
import { getMyBrandBusinessDetail } from '../../store/BusinessStore/actions/BrandBusinessActions/getMyBrandBusinessDetailActions';
import { useDispatch, useSelector } from 'react-redux';
import BrandLanding from '../../components/main/BrandLanding/BrandLanding';
import { useNavigate } from 'react-router';
import Popup from '../../components/popups/Popup/Popup';
import { SiLibreofficewriter } from 'react-icons/si';


const DashboardBrand = () => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isPopupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    dispatch(getMyBrandBusinessDetail(( status, response, message ) => {
      if (status === 302){
        setPopupOpen(true)
      }else{
        // Toast(response.message, 'error');
      }
    }))
  }, []);

  return (
    <div className='w-full h-full'>
      <BrandLanding />
      <Popup
        isOpen={isPopupOpen}
        onClose={() => setPopupOpen(false)}
        title="Business Registration"
        canDismiss
        icon={<SiLibreofficewriter color="0D9B50" size={110} className="my-[5rem]" />}
        content={<p className='text-[1.6rem] w-[80%] mx-auto text-center'>Please register your business to access the campaign creation features</p>}
        size="sm"
        primaryButton={{
          text: 'Add Business',
          onClick: () => {
            navigate("/brand/busisness")
            setPopupOpen(false);
          },
        }}
        secondaryButton={{
          text: 'Not Now',
          onClick: () => setPopupOpen(false),
        }}
      />
    </div>
  )
}

export default DashboardBrand;
