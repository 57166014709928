import React, { useState } from 'react';

const YouTubeOAuth = () => {
    const [authCode, setAuthCode] = useState(null);

    const handleConnectYouTube = () => {
        const clientId = '647395777111-ihuj3klciv80l73qr0hv8nmne2gud7mv.apps.googleusercontent.com'; // Your Google Client ID
        // const redirectUri = 'http://localhost:3000/influencer';  //  For Local
        const redirectUri = 'https://dev.paidpromo.ai/influencer'; // for dev
        const scope = [
            'https://www.googleapis.com/auth/youtube.upload',
            'https://www.googleapis.com/auth/youtube.readonly',
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile',
        ].join(' ');

        // Build the Google OAuth URL
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&access_type=offline&prompt=consent&include_granted_scopes=true`;
        // Redirect to Google OAuth2 for authentication
        window.location.href = authUrl;
    };

    // Retrieve the authorization code from the URL after redirect
    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            setAuthCode(code); // Save the code to state to display on the UI
        }
    }, []); // Run once on component mount

    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <button onClick={handleConnectYouTube} style={{ padding: '10px 20px', fontSize: '16px', background:"red" }}>
                Connect YouTube Account
            </button>
            {authCode && (
                <div style={{ marginTop: '20px', fontSize: '14px' }}>
                    <p>Authorization Code:</p>
                    <textarea value={authCode} readOnly rows="1" style={{ width: '80%', padding: '10px' }} />
                    <p style={{ fontSize: '12px', color: 'grey' }}>Copy this code and paste it in your application.</p>
                </div>
            )}
        </div>
    );
};

export default YouTubeOAuth;
