import React from 'react';

const Breadcrumbs = () => {
    return (
        <header className="w-full h-[7rem] flex justify-start items-end text-[1.4rem] text-[#38414A] bg-[#EDEEF1] pb-[1.4rem] px-[4rem]">
            <a href="/" className="hover:underline inter-font font-[500]">Home</a>
            <span className='mx-[.4rem]'>{'>'}</span>
            <a href="/brand/campaigns/create" className="hover:underline inter-font font-[500]">My Campaigns</a>
            <span className='mx-[.4rem]'>{'>'}</span>
            <a href="/basic-details" className="hover:underline inter-font font-[500] text-[#0D9B50]">Basic Details</a>
        </header>
    )
}

export default Breadcrumbs;
