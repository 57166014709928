import React, {useState} from 'react';
import {SidebarImg} from '../../../static/constant/imgConstants';
import GaugeChart from 'react-gauge-chart';
import YouTubeOAuth from "./YouTubeOAuth";

const Landing = () => {
    const [credits, setCredits] = useState(400);
    const [dashboardData, setDashboardData] = useState({
        campaigns: 10293,
        views: 89000,
        newCustomers: 2040,
        impressions: 10293,
        clicks: 89000,
        kpi: 2040,
    });

    const [percentageChanges, setPercentageChanges] = useState({
        campaignsChange: { value: 1.3, direction: 'up' },
        viewsChange: { value: 4.3, direction: 'down' },
        newCustomersChange: { value: 1.8, direction: 'up' },
        impressionsChange: { value: 1.3, direction: 'up' },
        clicksChange: { value: 4.3, direction: 'down' },
        kpiChange: { value: 1.8, direction: 'up' },
    });
    const formatPercentageChange = (change) => {
        const sign = change.direction === 'up' ? '+' : '-';
        const color = change.direction === 'up' ? 'text-green-500' : 'text-red-500';
        return (
            <p className={`${color} text-[1.6rem]`}>
                {sign}{change.value}% {change.direction === 'up' ? 'Up' : 'Down'} from {change.direction === 'up' ? 'past week' : 'yesterday'}
            </p>
        );
    };
    const calculateGaugePercentage = (points) => {
        const maxPoints = 1000;
        return points / maxPoints;
    };

    return (
        <div className='w-full h-full bg-[#EDEEF1] overflow-scroll'>
            <h2 className="text-[2.4rem] text-[#202224] font-semibold pl-4">Dashboard1</h2>
            <div style={{marginTop: '30px'}}>
                <YouTubeOAuth/>
            </div>
            {/* Credits Section */}
            <div className='w-full flex flex-col md:flex-row gap-[2rem] my-[4rem] px-[2rem]'>
                <div className='w-full md:w-[35%] flex bg-[#ffffff] rounded-[2rem]'>
                    <div className='w-full flex-col justify-center'>
                        <div className='flex justify-between p-4'>
                            <h2 className="text-[1.6rem] text-[#202224] font-semibold pl-4">Credits</h2>
                            <div className='flex'>
                                <div className='w-[6rem] h-auto'>
                                    <a href="#" className="mt-2">
                                        <img src={SidebarImg.crediticon} alt="Wishlist" className="w-full h-full mr-2"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='flex-col justify-center text-center my-[2rem]'>
                            <h2 className="text-[2.8rem] text-[#202224] font-semibold mb-[2rem] pl-4">{credits} Points</h2>
                            {/* Add the Gauge Chart */}
                            <GaugeChart
                                id="credit-gauge"
                                nrOfLevels={6}
                                colors={["#99C817", "#FDEB48", "#FED137", "#F7B11E", "#F26D24", "#D72626"]}  // Green, Yellow, Red
                                arcWidth={0.3}
                                percent={calculateGaugePercentage(credits)}  // Dynamic percentage
                                textColor="#202224"
                            />
                        </div>
                    </div>
                </div>

                {/* New Dashboard Section */}
                <div className='w-full grid grid-col-2 sm:grid-cols-3 gap-8'>
                    <div className='bg-white p-6 rounded-[1rem]'>
                        {/* Row: h3 and img tag in one row */}
                        <div className="flex justify-between">
                            <h3 className='text-[1.6rem] font-semibold text-[#202224]'>Campaigns</h3>
                            <img src={SidebarImg.campaign} alt="Campaigns Icon" className="w-[6rem] h-[6rem]"/>
                        </div>
                        {/* Column: p tags below */}
                        <div className="flex flex-col">
                            <p className='text-[2.4rem] font-bold mb-4'>{dashboardData.campaigns}</p>
                            {formatPercentageChange(percentageChanges.campaignsChange)}
                        </div>
                    </div>

                    <div className='bg-white p-6 rounded-[1rem]'>
                        <div className="flex justify-between">
                            <h3 className='text-[1.6rem] font-semibold text-[#202224]'>Views</h3>
                            <img src={SidebarImg.views} alt="Views Icon" className="w-[6rem] h-[6rem]"/>
                        </div>
                        <div className="flex flex-col">
                            <p className='text-[2.4rem] font-bold mb-4'>${dashboardData.views}</p>
                            {formatPercentageChange(percentageChanges.viewsChange)}
                        </div>
                    </div>

                    <div className='bg-white p-6 rounded-[1rem]'>
                        <div className="flex justify-between">
                            <h3 className='text-[1.6rem] font-semibold text-[#202224]'>New Customer</h3>
                            <img src={SidebarImg.customer} alt="New Customer Icon" className="w-[6rem] h-[6rem]"/>
                        </div>
                        <div className="flex flex-col">
                            <p className='text-[2.4rem] font-bold mb-4'>{dashboardData.newCustomers}</p>
                            {formatPercentageChange(percentageChanges.newCustomersChange)}
                        </div>
                    </div>

                    <div className='bg-white p-6 rounded-[1rem]'>
                        <div className="flex justify-between">
                            <h3 className='text-[1.6rem] font-semibold text-[#202224]'>Impression</h3>
                            <img src={SidebarImg.impression} alt="Impression Icon" className="w-[6rem] h-[6rem]"/>
                        </div>
                        <div className="flex flex-col">
                            <p className='text-[2.4rem] font-bold mb-4'>{dashboardData.impressions}</p>
                            {formatPercentageChange(percentageChanges.impressionsChange)}
                        </div>
                    </div>

                    <div className='bg-white p-6 rounded-[1rem]'>
                        <div className="flex justify-between">
                            <h3 className='text-[1.6rem] font-semibold text-[#202224]'>Clicks</h3>
                            <img src={SidebarImg.clicks} alt="Clicks Icon" className="w-[6rem] h-[6rem]"/>
                        </div>
                        <div className="flex flex-col">
                            <p className='text-[2.4rem] font-bold mb-4'>${dashboardData.clicks}</p>
                            {formatPercentageChange(percentageChanges.clicksChange)}
                        </div>
                    </div>

                    <div className='bg-white p-6 rounded-[1rem]'>
                        <div className="flex justify-between">
                            <h3 className='text-[1.6rem] font-semibold text-[#202224]'>KPI</h3>
                            <img src={SidebarImg.kip} alt="KPI Icon" className="w-[6rem] h-[6rem]"/>
                        </div>
                        <div className="flex flex-col">
                            <p className='text-[2.4rem] font-bold mb-4'>{dashboardData.kpi}</p>
                            {formatPercentageChange(percentageChanges.kpiChange)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Landing;
