import React from 'react';
import {FiTrendingDown, FiTrendingUp} from 'react-icons/fi';


const SummaryCard = ({ title, value, percentage, trend, imgSrc }) => {
    return (
        <div className="bg-white p-[2.2rem] rounded-[1.4rem] shadow-md">
            <div className="flex items-center justify-between">
                <div>
                    <h4 className="text-[1.6rem] text-[#202224] inter-font font-[600] ml-2">{title}</h4>
                    <h3 className="text-[2.8rem] inter-font font-[600] mt-4 ml-2">{value}</h3>
                </div>
                <img src={imgSrc} alt={title} className="h-[6rem] w-[6rem] my-[1.6rem]" />
            </div>

            <div className={`flex items-center text-[1.6rem] nunito-sans-font font-[500] mt-[3rem] ml-2 mb-4 ${trend === 'up' ? 'text-green-500' : 'text-red-500'}`}>
                {trend === 'up' ? <FiTrendingUp className="mr-2" /> : <FiTrendingDown className="mr-2" />}
                {percentage} {trend === 'up' ? 'Up' : 'Down'} from past week
            </div>
        </div>
    );
};

export default SummaryCard;
