import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BottomNavigation from './BrandCampaignLayout/BottomNavigation';
import {getAllRegion} from "../../../store/campaignStore/actions/CampaignMasterActions/getAllRegionActions";
import {getAllAgeGroup} from "../../../store/campaignStore/actions/CampaignMasterActions/getAllAgeGroupActions";
import {getAllNiche} from "../../../store/campaignStore/actions/CampaignMasterActions/getAllNicheActions";
import {getCountryByRegion} from "../../../store/campaignStore/actions/CampaignMasterActions/getCountryByRegionActions";
import {getStateByCountry} from "../../../store/campaignStore/actions/CampaignMasterActions/getStateByCountryActions";
import {
    setBrandCampaignCreationData,
    setBrandCampaignCreationStep
} from "../../../store/publicStore/actions/utilsActions/utilsActions";
import './AudienceDemographicDetails.css';
import MultiSelectDropdown from '../../common/MultiSelectDropdown/MultiSelectDropdown';


const AudienceDemographicDetails = () => {
    const dispatch = useDispatch()

    const { brandCampaignCreationStep, brandCampaignCreationData } = useSelector((state) => state.utilReducers);

    const getAllRegionReducerData = useSelector(state => state.getAllRegionReducer.data);
    const getCountryByRegionReducerData = useSelector(state => state.getCountryByRegionReducer?.data);
    const getStateByCountryReducerData = useSelector(state => state.getStateByCountryReducer?.data);
    const getAllAgeGroupReducerData = useSelector(state => state.getAllAgeGroupReducer?.data);
    const getAllNicheReducerData = useSelector(state => state.getAllNicheReducer?.data);
    const [formData, setFormData] = useState({
        region: brandCampaignCreationData?.audienceDemographicDetails?.region ? brandCampaignCreationData?.audienceDemographicDetails?.region : '',
        country: brandCampaignCreationData?.audienceDemographicDetails?.country ? brandCampaignCreationData?.audienceDemographicDetails?.country : '',
        state: brandCampaignCreationData?.audienceDemographicDetails?.state ? brandCampaignCreationData?.audienceDemographicDetails?.state : '',
        ageGroup: brandCampaignCreationData?.audienceDemographicDetails?.ageGroup ? brandCampaignCreationData?.audienceDemographicDetails?.ageGroup : '',
        gender: brandCampaignCreationData?.audienceDemographicDetails?.gender ? brandCampaignCreationData?.audienceDemographicDetails?.gender : '',
        niche: brandCampaignCreationData?.audienceDemographicDetails?.niche ? brandCampaignCreationData?.audienceDemographicDetails?.niche : ''
    });
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        dispatch(getAllRegion());
        dispatch(getAllAgeGroup());
        dispatch(getAllNiche());
    }, []);
    useEffect(() => {
        if (formData.region?.length > 0) {
            setFormData((prevData) => ({
                ...prevData,
                country: '',
                state: '',
            }));
            dispatch(getCountryByRegion({ regionId: formData.region }));
        }
    }, [formData.region]);
    

    useEffect(() => {
        if (formData.country?.length > 0) {
            setFormData((prevData) => ({
                ...prevData,
                state: '',
            }));
            dispatch(getStateByCountry({ countryId: formData.country }));
        }
    }, [formData.country]);

    // Validate the form and return errors
    const validateBannerForm = (data) => {
        let formErrors = {};

        if (data?.ageGroup?.length === 0) {
            formErrors.ageGroup = "Age Group is required";
        }
        if (!data?.country.trim()) {
            formErrors.country = "Country is required";
        }
        if (data?.niche?.length === 0) {
            formErrors.niche = "Niche is required";
        }
        if (!data?.region.trim()) {
            formErrors.region = "Region is required";
        }
        if (!data?.gender.trim()) {
            formErrors.gender = "Gender is required";
        }
        if (!data?.state.trim()) {
            formErrors.state = "state is required";
        }

        return formErrors;
    };

    const handleNext = () => {

        // Validate form data
        const formErrors = validateBannerForm(formData);

        if (Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors); // Show errors if validation fails
        } else {
            // Clear errors and save data to Redux store
            setFormErrors({});

            // Dispatch the campaign data to Redux
            dispatch(
                setBrandCampaignCreationData({
                    basicDetails: {
                        campaignName: brandCampaignCreationData?.basicDetails?.campaignName,
                        campaignPurpose: brandCampaignCreationData?.basicDetails?.campaignPurpose,
                        platform: brandCampaignCreationData?.basicDetails?.platform,
                        campaignType: brandCampaignCreationData?.basicDetails?.campaignType,
                    },
                    contentDetails: {
                        startDate: brandCampaignCreationData?.contentDetails?.startDate,
                        endDate: brandCampaignCreationData?.contentDetails?.endDate,
                        bannerTemplate: brandCampaignCreationData?.contentDetails?.bannerTemplate,
                        videoPosition: brandCampaignCreationData?.contentDetails?.videoPosition,
                        mediaFileLink: brandCampaignCreationData?.contentDetails?.mediaFileLink,
                        script: brandCampaignCreationData?.contentDetails?.script,
                        dialogue: brandCampaignCreationData?.contentDetails?.dialogue,
                        backgroundColor: brandCampaignCreationData?.contentDetails?.backgroundColor,
                        otherProperties: brandCampaignCreationData?.contentDetails?.otherProperties,
                        isLocationRequired: brandCampaignCreationData?.contentDetails?.isLocationRequired,
                        isBrandApprovalRequired: brandCampaignCreationData?.contentDetails?.isBrandApprovalRequired,
                        uploadDuration: brandCampaignCreationData?.contentDetails?.uploadDuration,
                    },
                    audienceDemographicDetails: {
                        ageGroup: formData?.ageGroup,
                        country: formData?.country,
                        niche: formData?.niche,
                        region: formData?.region,
                        gender: formData?.gender,
                        state: formData?.state,
                    }
                })
            );

            // Proceed to the next step
            dispatch(
                setBrandCampaignCreationStep(brandCampaignCreationStep + 1)
            );
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
        setFormErrors((prevState) => { const { [e.target.id]: _, ...updatedObject } = prevState; return updatedObject; });
    };

    const handleSelect = (items, key) => {
        setFormData({
            ...formData,
            [key]: items?.map(item => (item.id))
        });
        setFormErrors((prevState) => { const { [key]: _, ...updatedObject } = prevState; return updatedObject; });
    };

    return (
        <div className="w-full h-[80%] bg-[#FFFFFF66] shadow-xl shadow-[#E3E8EF] rounded-[0.6rem] p-[3.2rem]">
            <div className="w-full h-full !overflow-y-scroll no-scrollbar">
                <h2 className="text-[2rem] text-[#111729] inter-font font-[500] mb-6">Geography</h2>
                <form onSubmit={() => {}}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label htmlFor="region" className="text-[1.6rem] text-[#111729] inter-font font-[400]">Region<span className="text-red-500">*</span></label>
                            <select
                                id="region"
                                value={formData.region}
                                onChange={handleChange}
                                className="w-full px-4 py-4 text-[1.6rem] inter-font font-[400] border border-gray-300 rounded-lg focus:outline-none"
                                required
                            >
                                <option value="" selected>Select Region</option>
                                {Array.isArray(getAllRegionReducerData) && getAllRegionReducerData.map((items) => (
                                    <option key={items.regionId} value={items.regionId}>
                                        {items.name}
                                    </option>
                                ))}
                            </select>
                            {formErrors.region && <span className=" text-[1.6rem] inter-font font-[400] text-red-500">{formErrors.region}</span>}
                        </div>
                        <div>
                            <label htmlFor="country" className="text-[1.6rem] text-[#111729] inter-font font-[400]">Country<span className="text-red-500">*</span></label>
                            <select
                                id="country"
                                value={formData.country}
                                onChange={handleChange}
                                className="w-full px-4 py-4 text-[1.6rem] inter-font font-[400] border border-gray-300 rounded-lg focus:outline-none" required>
                                <option value="" selected disabled>{!formData.region ? "Select region to choose" : "Select from Option"}</option>
                                {Array.isArray(getCountryByRegionReducerData) && getCountryByRegionReducerData.map((items) => (
                                    <option key={items.countryId} value={items.countryId}>
                                        {items.countryName}
                                    </option>
                                ))}
                            </select>
                            {formErrors.country && <span className="text-[1.6rem] inter-font font-[400] text-red-500">{formErrors.country}</span>}
                        </div>
                        <div>
                            <label htmlFor="state" className="text-[1.6rem] text-[#111729] inter-font font-[400]">State<span className="text-red-500">*</span></label>
                            <select
                                id="state"
                                value={formData.state}
                                onChange={handleChange}
                                className="w-full px-4 py-4 text-[1.6rem] inter-font font-[400] border border-gray-300 rounded-lg focus:outline-none" required>
                                <option value="" selected>{!formData.country ? "Select country to choose" : "Select from Option"}</option>
                                {Array.isArray(getStateByCountryReducerData) && getStateByCountryReducerData.map((items) => (
                                    <option key={items.stateId} value={items.stateId}>
                                        {items.name}
                                    </option>
                                ))}
                            </select>
                            {formErrors.state && <span className="text-[1.6rem] inter-font font-[400] text-red-500">{formErrors.state}</span>}
                        </div>
                    </div>

                    <h2 className="text-[2rem] text-[#111729] inter-font font-[500] mt-[4rem] mb-6">Viewer Demographics</h2>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label htmlFor="ageGroup" className="text-[1.6rem] text-[#111729] inter-font font-[400]">
                                Age Group<span className="text-red-500">*</span>
                            </label>
                            <MultiSelectDropdown 
                                options={getAllAgeGroupReducerData.map((items) => (
                                    {...items, id: items.ageGroupId, label: items.ageRangeLabel}
                                ))} 
                                onSelect={(items) => handleSelect(items, 'ageGroup')} 
                            />
                            {formErrors.ageGroup && (
                                <span className="text-[1.6rem] inter-font font-[400] text-red-500">
                                    {formErrors.ageGroup}
                                </span>
                            )}
                        </div>

                        <div>
                            <label htmlFor="gender" className="text-[1.6rem] text-[#111729] inter-font font-[400]">Gender<span className="text-red-500">*</span></label>
                            <select id="gender" value={formData.gender} onChange={handleChange} className="w-full px-4 py-4 text-[1.6rem] inter-font font-[400] border border-gray-300 rounded-lg focus:outline-none" required>
                                <option value="">Select from Option</option>
                                <option value="MALE">Male</option>
                                <option value="FEMALE">Female</option>
                                <option value="OTHER">Other</option>
                                <option value="ALL">All</option>
                            </select>
                            {formErrors.gender && <span className="text-[1.6rem] inter-font font-[400] text-red-500">{formErrors.gender}</span>}
                        </div>
                        <div>
                            <label htmlFor="niche" className="text-[1.6rem] text-[#111729] inter-font font-[400]">Niche<span className="text-red-500">*</span></label>
                            <MultiSelectDropdown 
                                options={getAllNicheReducerData.map((items) => (
                                    {...items, id: items.nicheId, label: items.nicheName}
                                ))} 
                                onSelect={(items) => handleSelect(items, 'niche')} 
                            />
                            {formErrors.niche && <span className="text-[1.6rem] inter-font font-[400] text-red-500">{formErrors.niche}</span>}
                        </div>
                    </div>

                    <BottomNavigation isNextDisabled={Object.keys(formErrors).length > 0} handleNext={handleNext} />
                </form>
            </div>
        </div>
    );
};

export default AudienceDemographicDetails;
