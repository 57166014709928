export const actionTypes = {
	// Utils
	USER_LOGOUT: 'USER_LOGOUT',
	SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
	SET_SESSON_TOKEN: 'SET_SESSON_TOKEN',
	SET_EMAIL_FOR_LOGIN: 'SET_EMAIL_FOR_LOGIN',
	SET_EMAIL_FOR_FORGET_PASSWORD: 'SET_EMAIL_FOR_FORGET_PASSWORD',
	SET_USER_TYPE: 'SET_USER_TYPE',
	SET_USER_ID: 'SET_USER_ID',
	SET_BRAND_CAMPAIGN_CREATION_STEP: 'SET_BRAND_CAMPAIGN_CREATION_STEP',
	SET_BRAND_CAMPAIGN_CREATION_DATA: 'SET_BRAND_CAMPAIGN_CREATION_DATA',

	// Public
	GET_ALL_COUNTRAY_REQUEST: 'GET_ALL_COUNTRAY_REQUEST',
	GET_ALL_COUNTRAY_SUCCESS: 'GET_ALL_COUNTRAY_SUCCESS',
	GET_ALL_COUNTRAY_FAILURE: 'GET_ALL_COUNTRAY_FAILURE',
	GET_ALL_COUNTRAY_RESET: 'GET_ALL_COUNTRAY_RESET',
	GET_ALL_COUNTRAY_RESET_ALL: 'GET_ALL_COUNTRAY_RESET_ALL',

	// Auth
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	LOGIN_RESET: 'LOGIN_RESET',
	LOGIN_RESET_ALL: 'LOGIN_RESET_ALL',

	GOOGLE_USER_LOGIN_REQUEST: 'GOOGLE_USER_LOGIN_REQUEST',
	GOOGLE_USER_LOGIN_SUCCESS: 'GOOGLE_USER_LOGIN_SUCCESS',
	GOOGLE_USER_LOGIN_FAILURE: 'GOOGLE_USER_LOGIN_FAILURE',
	GOOGLE_USER_LOGIN_RESET: 'GOOGLE_USER_LOGIN_RESET',
	GOOGLE_USER_LOGIN_RESET_ALL: 'GOOGLE_USER_LOGIN_RESET_ALL',

	GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
	GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
	GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',
	GET_USER_INFO_RESET: 'GET_USER_INFO_RESET',
	GET_USER_INFO_RESET_ALL: 'GET_USER_INFO_RESET_ALL',

	CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
	CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
	CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
	CREATE_USER_RESET: 'CREATE_USER_RESET',
	CREATE_USER_RESET_ALL: 'CREATE_USER_RESET_ALL',

	VERIFY_OTP_AND_CREATE_USER_REQUEST: 'VERIFY_OTP_AND_CREATE_USER_REQUEST',
	VERIFY_OTP_AND_CREATE_USER_SUCCESS: 'VERIFY_OTP_AND_CREATE_USER_SUCCESS',
	VERIFY_OTP_AND_CREATE_USER_FAILURE: 'VERIFY_OTP_AND_CREATE_USER_FAILURE',
	VERIFY_OTP_AND_CREATE_USER_RESET: 'VERIFY_OTP_AND_CREATE_USER_RESET',
	VERIFY_OTP_AND_CREATE_USER_RESET_ALL: 'VERIFY_OTP_AND_CREATE_USER_RESET_ALL',

	SEND_OTP_FOR_FORGET_PASSWORD_REQUEST: 'SEND_OTP_FOR_FORGET_PASSWORD_REQUEST',
	SEND_OTP_FOR_FORGET_PASSWORD_SUCCESS: 'SEND_OTP_FOR_FORGET_PASSWORD_SUCCESS',
	SEND_OTP_FOR_FORGET_PASSWORD_FAILURE: 'SEND_OTP_FOR_FORGET_PASSWORD_FAILURE',
	SEND_OTP_FOR_FORGET_PASSWORD_RESET: 'SEND_OTP_FOR_FORGET_PASSWORD_RESET',
	SEND_OTP_FOR_FORGET_PASSWORD_RESET_ALL: 'SEND_OTP_FOR_FORGET_PASSWORD_RESET_ALL',

	VERIFY_OTP_FOR_FORGET_PASSWORD_REQUEST: 'VERIFY_OTP_FOR_FORGET_PASSWORD_REQUEST',
	VERIFY_OTP_FOR_FORGET_PASSWORD_SUCCESS: 'VERIFY_OTP_FOR_FORGET_PASSWORD_SUCCESS',
	VERIFY_OTP_FOR_FORGET_PASSWORD_FAILURE: 'VERIFY_OTP_FOR_FORGET_PASSWORD_FAILURE',
	VERIFY_OTP_FOR_FORGET_PASSWORD_RESET: 'VERIFY_OTP_FOR_FORGET_PASSWORD_RESET',
	VERIFY_OTP_FOR_FORGET_PASSWORD_RESET_ALL: 'VERIFY_OTP_FOR_FORGET_PASSWORD_RESET_ALL',

	FORGET_PASSWORD_RESET_REQUEST: 'FORGET_PASSWORD_RESET_REQUEST',
	FORGET_PASSWORD_RESET_SUCCESS: 'FORGET_PASSWORD_RESET_SUCCESS',
	FORGET_PASSWORD_RESET_FAILURE: 'FORGET_PASSWORD_RESET_FAILURE',
	FORGET_PASSWORD_RESET_RESET: 'FORGET_PASSWORD_RESET_RESET',
	FORGET_PASSWORD_RESET_RESET_ALL: 'FORGET_PASSWORD_RESET_RESET_ALL',


	SEND_OTP_TO_CREATE_USER_REQUEST: 'SEND_OTP_TO_CREATE_USER_REQUEST',
	SEND_OTP_TO_CREATE_USER_SUCCESS: 'SEND_OTP_TO_CREATE_USER_SUCCESS',
	SEND_OTP_TO_CREATE_USER_FAILURE: 'SEND_OTP_TO_CREATE_USER_FAILURE',
	SEND_OTP_TO_CREATE_USER_RESET: 'SEND_OTP_TO_CREATE_USER_RESET',
	SEND_OTP_TO_CREATE_USER_RESET_ALL: 'SEND_OTP_TO_CREATE_USER_RESET_ALL',

	USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
	USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
	USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
	USER_LOGIN_RESET: 'USER_LOGIN_RESET',
	USER_LOGIN_RESET_ALL: 'USER_LOGIN_RESET_ALL',

	VERIFY_OTP_FOR_LOGIN_REQUEST: 'VERIFY_OTP_FOR_LOGIN_REQUEST',
	VERIFY_OTP_FOR_LOGIN_SUCCESS: 'VERIFY_OTP_FOR_LOGIN_SUCCESS',
	VERIFY_OTP_FOR_LOGIN_FAILURE: 'VERIFY_OTP_FOR_LOGIN_FAILURE',
	VERIFY_OTP_FOR_LOGIN_RESET: 'VERIFY_OTP_FOR_LOGIN_RESET',
	VERIFY_OTP_FOR_LOGIN_RESET_ALL: 'VERIFY_OTP_FOR_LOGIN_RESET_ALL',

	GET_ALL_REGION_REQUEST: 'GET_ALL_REGION_REQUEST',
	GET_ALL_REGION_SUCCESS: 'GET_ALL_REGION_SUCCESS',
	GET_ALL_REGION_FAILURE: 'GET_ALL_REGION_FAILURE',
	GET_ALL_REGION_RESET: 'GET_ALL_REGION_RESET',
	GET_ALL_REGION_RESET_ALL: 'GET_ALL_REGION_RESET_ALL',

	GET_COUNTRY_BY_REGION_REQUEST: 'GET_COUNTRY_BY_REGION_REQUEST',
	GET_COUNTRY_BY_REGION_SUCCESS: 'GET_COUNTRY_BY_REGION_SUCCESS',
	GET_COUNTRY_BY_REGION_FAILURE: 'GET_COUNTRY_BY_REGION_FAILURE',
	GET_COUNTRY_BY_REGION_RESET: 'GET_COUNTRY_BY_REGION_RESET',
	GET_COUNTRY_BY_REGION_RESET_ALL: 'GET_COUNTRY_BY_REGION_RESET_ALL',


	GET_STATE_BY_COUNTRY_REQUEST: 'GET_STATE_BY_COUNTRY_REQUEST',
	GET_STATE_BY_COUNTRY_SUCCESS: 'GET_STATE_BY_COUNTRY_SUCCESS',
	GET_STATE_BY_COUNTRY_FAILURE: 'GET_STATE_BY_COUNTRY_FAILURE',
	GET_STATE_BY_COUNTRY_RESET: 'GET_STATE_BY_COUNTRY_RESET',
	GET_STATE_BY_COUNTRY_RESET_ALL: 'GET_STATE_BY_COUNTRY_RESET_ALL',

	GET_ALL_AGE_GROUP_REQUEST: 'GET_ALL_AGE_GROUP_REQUEST',
	GET_ALL_AGE_GROUP_SUCCESS: 'GET_ALL_AGE_GROUP_SUCCESS',
	GET_ALL_AGE_GROUP_FAILURE: 'GET_ALL_AGE_GROUP_FAILURE',
	GET_ALL_AGE_GROUP_RESET: 'GET_ALL_AGE_GROUP_RESET',
	GET_ALL_AGE_GROUP_RESET_ALL: 'GET_ALL_AGE_GROUP_RESET_ALL',


	GET_ALL_NICHE_REQUEST: 'GET_ALL_NICHE_REQUEST',
	GET_ALL_NICHE_SUCCESS: 'GET_ALL_NICHE_SUCCESS',
	GET_ALL_NICHE_FAILURE: 'GET_ALL_NICHE_FAILURE',
	GET_ALL_NICHE_RESET: 'GET_ALL_NICHE_RESET',
	GET_ALL_NICHE_RESET_ALL: 'GET_ALL_NICHE_RESET_ALL',


	GET_BUDGET_REQUEST: 'GET_BUDGET_REQUEST',
	GET_BUDGET_SUCCESS: 'GET_BUDGET_SUCCESS',
	GET_BUDGET_FAILURE: 'GET_BUDGET_FAILURE',
	GET_BUDGET_RESET: 'GET_BUDGET_RESET',
	GET_BUDGET_RESET_ALL: 'GET_BUDGET_RESET_ALL',

	GET_MAX_VIEWS_REQUEST: 'GET_MAX_VIEWS_REQUEST',
	GET_MAX_VIEWS_SUCCESS: 'GET_MAX_VIEWS_SUCCESS',
	GET_MAX_VIEWS_FAILURE: 'GET_MAX_VIEWS_FAILURE',
	GET_MAX_VIEWS_RESET: 'GET_MAX_VIEWS_RESET',
	GET_MAX_VIEWS_RESET_ALL: 'GET_MAX_VIEWS_RESET_ALL',


	GET_BRAND_CAMPAIGN_REQUEST: 'GET_BRAND_CAMPAIGN_REQUEST',
	GET_BRAND_CAMPAIGN_SUCCESS: 'GET_BRAND_CAMPAIGN_SUCCESS',
	GET_BRAND_CAMPAIGN_FAILURE: 'GET_BRAND_CAMPAIGN_FAILURE',
	GET_BRAND_CAMPAIGN_RESET: 'GET_BRAND_CAMPAIGN_RESET',
	GET_BRAND_CAMPAIGN_RESET_ALL: 'GET_BRAND_CAMPAIGN_RESET_ALL',

	GET_ALL_BANNER_TEMPLATE_REQUEST: 'GET_ALL_BANNER_TEMPLATE_REQUEST',
	GET_ALL_BANNER_TEMPLATE_SUCCESS: 'GET_ALL_BANNER_TEMPLATE_SUCCESS',
	GET_ALL_BANNER_TEMPLATE_FAILURE: 'GET_ALL_BANNER_TEMPLATE_FAILURE',
	GET_ALL_BANNER_TEMPLATE_RESET: 'GET_ALL_BANNER_TEMPLATE_RESET',
	GET_ALL_BANNER_TEMPLATE_RESET_ALL: 'GET_ALL_BANNER_TEMPLATE_RESET_ALL',


	UPLOAD_CAMPAIGN_FILE_REQUEST: 'UPLOAD_CAMPAIGN_FILE_REQUEST',
	UPLOAD_CAMPAIGN_FILE_SUCCESS: 'UPLOAD_CAMPAIGN_FILE_SUCCESS',
	UPLOAD_CAMPAIGN_FILE_FAILURE: 'UPLOAD_CAMPAIGN_FILE_FAILURE',
	UPLOAD_CAMPAIGN_FILE_RESET: 'UPLOAD_CAMPAIGN_FILE_RESET',
	UPLOAD_CAMPAIGN_FILE_RESET_ALL: 'UPLOAD_CAMPAIGN_FILE_RESET_ALL',

	CREATE_CAMPAIGN_REQUEST: 'CREATE_CAMPAIGN_REQUEST',
	CREATE_CAMPAIGN_SUCCESS: 'CREATE_CAMPAIGN_SUCCESS',
	CREATE_CAMPAIGN_FAILURE: 'CREATE_CAMPAIGN_FAILURE',
	CREATE_CAMPAIGN_RESET: 'CREATE_CAMPAIGN_RESET',
	CREATE_CAMPAIGN_RESET_ALL: 'CREATE_CAMPAIGN_RESET_ALL',

	GET_MY_BRAND_BUSINESS_REQUEST: 'GET_MY_BRAND_BUSINESS_REQUEST',
	GET_MY_BRAND_BUSINESS_SUCCESS: 'GET_MY_BRAND_BUSINESS_SUCCESS',
	GET_MY_BRAND_BUSINESS_FAILURE: 'GET_MY_BRAND_BUSINESS_FAILURE',
	GET_MY_BRAND_BUSINESS_RESET: 'GET_MY_BRAND_BUSINESS_RESET',
	GET_MY_BRAND_BUSINESS_RESET_ALL: 'GET_MY_BRAND_BUSINESS_RESET_ALL',

	SESRCH_BUSINESS_PLACES_REQUEST: 'SESRCH_BUSINESS_PLACES_REQUEST',
	SESRCH_BUSINESS_PLACES_SUCCESS: 'SESRCH_BUSINESS_PLACES_SUCCESS',
	SESRCH_BUSINESS_PLACES_FAILURE: 'SESRCH_BUSINESS_PLACES_FAILURE',
	SESRCH_BUSINESS_PLACES_RESET: 'SESRCH_BUSINESS_PLACES_RESET',
	SESRCH_BUSINESS_PLACES_RESET_ALL: 'SESRCH_BUSINESS_PLACES_RESET_ALL',

	REGISTER_BUSINESS_REQUEST: 'REGISTER_BUSINESS_REQUEST',
	REGISTER_BUSINESS_SUCCESS: 'REGISTER_BUSINESS_SUCCESS',
	REGISTER_BUSINESS_FAILURE: 'REGISTER_BUSINESS_FAILURE',
	REGISTER_BUSINESS_RESET: 'REGISTER_BUSINESS_RESET',
	REGISTER_BUSINESS_RESET_ALL: 'REGISTER_BUSINESS_RESET_ALL',



}

